/* eslint-disable no-undef */
import axios from 'axios'
import store from '@/store'
import { getOauthInstance } from './oauthWrapper'

const JSONbigint = require('json-bigint')({ storeAsString: true, constructorAction: 'ignore' })

const getBaseUrl = () => {
  const overrideBaseUriValue =
    typeof config !== 'undefined' && typeof config.VUE_APP_BASE_URL !== 'undefined'

  return overrideBaseUriValue ? config.VUE_APP_BASE_URL : process.env.VUE_APP_BASE_URL
}

const tokenizedAxios = axios.create({
  baseURL: getBaseUrl(),
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFTOKEN'
})

// Check if the access token has expired
const isTokenExpired = () => {
  const FIVE_SECS = 5
  const { expiresAt } = store.getters['auth/allTokens']
  return Date.now() > expiresAt - FIVE_SECS
}

const axiosRequestInterceptor = async (config) => {
  const auth = getOauthInstance()
  try {
    if (isTokenExpired()) {
      // Token is expired, refresh it
      const newAccessToken = await auth.refreshAccessToken()
      if (newAccessToken) {
        config.headers['Authorization'] = `Bearer ${newAccessToken}`
      } else {
        throw new Error('Could not obtain new refresh and access tokens')
      }
    } else {
      const token = store.getters['auth/accessToken']
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
      } else {
        auth.logout()
      }
    }
  } catch (error) {
    console.error('Error refreshing token:', error)
    return Promise.reject(error)
  }
  return config
}

// Interceptor to attach the bearer token to each request
const axiosResponseInterceptorSuccess = (response) => {
  if (
    response.headers['content-type'] === 'application/json' &&
    'data' in response &&
    typeof response.data === 'string'
  ) {
    try {
      response.data = JSONbigint.parse(response.data)
      return response
    } catch (error) {
      console.error(error)
    }
  }
  return response
}

const axiosFailureInterceptor = (error) => {
  return Promise.reject(error)
}

tokenizedAxios.interceptors.request.use(axiosRequestInterceptor, (error) => Promise.reject(error))
tokenizedAxios.interceptors.response.use(axiosResponseInterceptorSuccess, axiosFailureInterceptor)

export default tokenizedAxios
