import meService from './me'
import organizationService from './organization'
import applicationService from './application'
import queryParamService from './queryParam'
import userService from './user'

export default {
  meService,
  userService,
  organizationService,
  applicationService,
  queryParamService
}
