import tokenizedAxios from '@/plugins/auth/axiosWrapper'
import store from '@/store'
import pkceChallenge from 'pkce-challenge'
import Deferred from 'promise-deferred'
import axios from 'axios'

const AUTH_CONFIG = {
  baseUrl: process.env.VUE_APP_BASE_URL,
  authBaseUrl: process.env.VUE_APP_AUTH_URL,
  scope: 'openid email profile',
  redirectUri: process.env.VUE_APP_REDIRECT_URI,
  memberServiceUrl: process.env.VUE_APP_MEMBER_SERVICE_URL,
  clientId: process.env.VUE_APP_CLIENT_ID,
  state: process.env.VUE_APP_STATE,
  authorizationEndpoint: `${process.env.VUE_APP_AUTH_URL}/oauth/authorize`,
  tokenEndpoint: `${process.env.VUE_APP_AUTH_URL}/oauth/token`,
  loginLink: '/login/',
  userMe: 'user_accounts/whoami/'
}

let authService = null

class OAuth2GatewayPlugin {
  constructor() {
    AUTH_CONFIG.redirectUri = this.redirectUri() ?? window.location.origin
    AUTH_CONFIG.baseUrl = this.getBaseUrl()
    AUTH_CONFIG.memberServiceUrl = this.memberServiceUrl()
    AUTH_CONFIG.authBaseUrl = this.getValidatorUrl()
    AUTH_CONFIG.authorizationEndpoint = `${AUTH_CONFIG.authBaseUrl}/oauth/authorize`
    AUTH_CONFIG.tokenEndpoint = `${AUTH_CONFIG.authBaseUrl}/oauth/token`

    const storeTokens = store.getters['auth/allTokens']
    this.accessToken = storeTokens.accessToken
    this.refreshToken = storeTokens.refreshToken
    this.idToken = storeTokens.idToken
    this.expiresAt = storeTokens.expiresAt
    this.codeVerifier = storeTokens.codeVerifier
    this.refreshExpiresAt = storeTokens.refreshExpiresAt
    this.idExpiresAt = storeTokens.idExpiresAt

    this.tokenExpiry = null
    this.refreshTimeout = null
    this.loading = false
    this.waitingForAuth = []
    this.userData = null
  }

  getLoginLink() {
    let url = new URL(AUTH_CONFIG.baseUrl + AUTH_CONFIG.loginLink)
    url.searchParams.append('next', window.location.href)
    return url.toString()
  }

  denyAccess() {
    window.location.href = '/403'
  }

  getConfiguration() {
    // eslint-disable-next-line no-undef
    return config
  }

  getBaseUrl() {
    const overrideBaseUriValue =
      typeof this.getConfiguration() !== 'undefined' &&
      typeof this.getConfiguration().VUE_APP_BASE_URL !== 'undefined'

    return overrideBaseUriValue
      ? this.getConfiguration().VUE_APP_BASE_URL
      : process.env.VUE_APP_BASE_URL
  }

  memberServiceUrl() {
    const overrideMemberServiceUriValue =
      typeof this.getConfiguration() !== 'undefined' &&
      typeof this.getConfiguration().VUE_APP_MEMBER_SERVICE_URL !== 'undefined'

    return overrideMemberServiceUriValue
      ? this.getConfiguration().VUE_APP_MEMBER_SERVICE_URL
      : process.env.VUE_APP_MEMBER_SERVICE_URL
  }

  getValidatorUrl() {
    const overrideValidatorEndpointUriValue =
      typeof this.getConfiguration() !== 'undefined' &&
      typeof this.getConfiguration().VUE_APP_AUTH_URL !== 'undefined'

    return overrideValidatorEndpointUriValue
      ? this.getConfiguration().VUE_APP_AUTH_URL
      : process.env.VUE_APP_AUTH_URL
  }

  redirectUri() {
    const overrideRedirectUri =
      typeof this.getConfiguration() !== 'undefined' &&
      typeof this.getConfiguration().VUE_APP_REDIRECT_URI !== 'undefined'

    return overrideRedirectUri
      ? this.getConfiguration().VUE_APP_REDIRECT_URI
      : process.env.VUE_APP_REDIRECT_URI
  }

  /**
   * Initiates the PKCE flow by redirecting to the authorization server
   */
  async login() {
    const { code_verifier, code_challenge: codeChallenge } = await pkceChallenge()
    this.codeVerifier = code_verifier
    store.commit('auth/SET', { path: null, key: 'codeVerifier', data: this.codeVerifier })

    const params = new URLSearchParams({
      response_type: 'code',
      client_id: AUTH_CONFIG.clientId,
      redirect_uri: AUTH_CONFIG.redirectUri,
      scope: AUTH_CONFIG.scope,
      state: AUTH_CONFIG.state,
      code_challenge: codeChallenge,
      code_challenge_method: 'S256'
    })

    window.location = `${AUTH_CONFIG.authorizationEndpoint}?${params.toString()}`
  }

  setTokens(tokens) {
    const { access_token, refresh_token, expires_at, id_token, refresh_expires_at, id_expires_at } =
      tokens

    this.accessToken = access_token
    this.refreshToken = refresh_token
    this.idToken = id_token
    this.expiresAt = expires_at
    this.refreshExpiresAt = refresh_expires_at
    this.idExpiresAt = id_expires_at
    this.tokenExpiry = Date.now() + expires_at * 1000
  }

  // Refresh the token using the refresh token
  async refreshAccessToken() {
    if (!this.refreshToken) {
      throw new Error('No refresh token available.')
    }

    try {
      const response = await axios.post(
        AUTH_CONFIG.tokenEndpoint,
        new URLSearchParams({
          grant_type: 'refresh_token',
          client_id: AUTH_CONFIG.clientId,
          refresh_token: this.refreshToken
        }),
        {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }
      )

      this.setTokens(response.data)
      return this.accessToken
    } catch (error) {
      console.error('Failed to refresh token:', error)
      this.logout() // You can redirect to login if refresh fails
      throw error
    }
  }

  async refreshTokens() {
    if (!this.refreshToken) {
      this.logout()
      return
    }

    try {
      const tokenResponse = await axios.post(
        AUTH_CONFIG.tokenEndpoint,
        new URLSearchParams({
          grant_type: 'refresh_token',
          client_id: AUTH_CONFIG.clientId,
          refresh_token: this.refreshToken
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      )

      this.setTokens(tokenResponse.data)
    } catch (error) {
      console.error('Failed to refresh token:', error)
      this.logout()
    }
  }

  /**
   * Handles the redirect back from the authorization server
   */
  async handleRedirectCallback() {
    const urlParams = new URLSearchParams(window.location.search)
    const code = urlParams.get('code')

    this.loading = true
    store.commit('user/SET', { path: 'me', key: 'loading', data: this.loading })

    if (code) {
      this.codeVerifier = store.state.auth.codeVerifier
      if (!this.codeVerifier) {
        throw new Error('Code verifier not found.')
      }

      try {
        // Exchange the authorization code for tokens
        const tokenResponse = await axios.post(
          AUTH_CONFIG.tokenEndpoint,
          new URLSearchParams({
            grant_type: 'authorization_code',
            client_id: AUTH_CONFIG.clientId,
            code: code,
            redirect_uri: AUTH_CONFIG.redirectUri,
            code_verifier: this.codeVerifier
          }),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        )

        this.setTokens(tokenResponse.data)

        store.dispatch('auth/setTokens', {
          accessToken: this.accessToken,
          refreshToken: this.refreshToken,
          idToken: this.idToken,
          expiresAt: this.expiresAt,
          refreshExpiresAt: this.refreshExpiresAt,
          idExpiresAt: this.idExpiresAt
        })

        const FIVE_SECS = 5
        this.tokenExpiry = this.expiresAt * 1000 - FIVE_SECS

        store.commit('user/SET', { path: 'me', key: 'loading', data: true })
        await this.me()
        store.commit('user/SET', { path: 'me', key: 'loading', data: false })
      } catch (error) {
        console.log(error)
      }

      // Clear PKCE code verifier from storage
      store.commit('auth/SET', { path: null, key: 'codeVerifier', data: null })

      // Remove the code from the URL
      window.history.replaceState({}, document.title, window.location.pathname)
    }

    this.loading = false
    store.commit('user/SET', { path: 'me', key: 'loading', data: this.loading })
  }

  /**
   * Returns the access token
   */
  getAccessToken() {
    return this.accessToken
  }

  /**
   * Checks if the user is authenticated
   */
  isAuthenticated() {
    return !!this.accessToken
  }

  /**
   * Returns the currently logged user
   */
  async me() {
    if (this.isAuthenticated()) {
      const response = await tokenizedAxios.get(
        `${AUTH_CONFIG.memberServiceUrl}/${AUTH_CONFIG.userMe}`,
        {
          withCredentials: true
        }
      )
      this.userData = response.data
      store.commit('user/SET', { path: 'me', key: 'data', data: this.userData })

      return Promise.resolve(this.userData)
    }
    // If not authenticated, return a promise that'll get resolved when we get authenticated.
    let p = new Deferred()
    this.waitingForAuth.push(p)
    return p.promise
  }

  /**
   * Logs out the user
   */
  logout(redirect_url) {
    clearTimeout(this.refreshTimeout)

    this.accessToken = null
    this.refreshToken = null
    this.tokenExpiry = null

    store.commit('user/RESET', { path: 'me' })
    store.dispatch('auth/clearTokens')
    sessionStorage.clear()

    const url = new URL(`${AUTH_CONFIG.baseUrl}/logout`)
    url.searchParams.append(
      'next',
      redirect_url || this.getLoginLink() || 'https://simbachain.com/blocks'
    )
    window.location.href = url.toString()
  }
}

/**
 * Gets an instance of the OAuth2GatewayPlugin
 * @returns {OAuth2GatewayPlugin}
 */
export const getOauthInstance = () => {
  if (!authService) {
    authService = new OAuth2GatewayPlugin()
  }
  return authService
}

export const oauth = {
  install(Vue) {
    Vue.prototype.$oauth = getOauthInstance()
  }
}
