/* eslint-disable no-undef */
import app from '@/main'

const getMemberServiceUrl = () => {
  const overrideMemberServiceUriValue =
    typeof config !== 'undefined' && typeof config.VUE_APP_MEMBER_SERVICE_URL !== 'undefined'

  return overrideMemberServiceUriValue
    ? config.VUE_APP_MEMBER_SERVICE_URL
    : process.env.VUE_APP_MEMBER_SERVICE_URL
}

const updateUser = async (param) => {
  try {
    const memberServiceUrl = getMemberServiceUrl()
    const res = await app.$http.put(
      `${memberServiceUrl}/user_accounts/${param.userId}`,
      param.payload
    )
    return { success: true, data: res.data }
  } catch (e) {
    return { success: false, data: e.response?.data?.errors?.map((error) => error.detail) }
  }
}

export default {
  async updateUserAccount(param) {
    return await updateUser(param)
  }
}
