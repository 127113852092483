import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import VuexPersistence from 'vuex-persist'
import Crypto from 'crypto-js'
import Cookie from 'js-cookie'
import { uuid } from 'vue-uuid'

const cookieName = process.env.VUE_APP_VUEXLS_COOKIE_NAME
// Get the encryption token from cookie or generate a new one.
const encryptionToken = Cookie.get(cookieName) || uuid.v4()
// Store the encryption token in a secure cookie.
Cookie.set(cookieName, encryptionToken, { secure: true, expires: 180 })

import scd from './modules/ssCached/scd'
import explorer from './modules/ssCached/explorer'
import graphql from './modules/ssCached/graphql'
import permission from './modules/ssCached/permission'
import contracts from './modules/ssCached/contracts'
import notifications from './modules/ssCached/notifications'
import apiEndpoints from './modules/ssCached/apiEndpoints'
import auth from './modules/ssCached/auth'

import theme from './modules/lsCached/theme'
import user from './modules/lsCached/user'

import layout from './modules/mmCached/layout'
import event from './modules/mmCached/event'

Vue.use(Vuex)
const vuexLocal = new VuexPersistence({
  modules: ['theme', 'user'],
  storage: {
    getItem: (key) => {
      // Get the store from local storage.
      const store = window.localStorage.getItem(key)

      if (store) {
        try {
          // Decrypt the store retrieved from local storage
          // using our encryption token stored in cookies.
          const bytes = Crypto.AES.decrypt(store, encryptionToken)
          return JSON.parse(bytes.toString(Crypto.enc.Utf8))
        } catch (e) {
          // The store will be reset if decryption fails.
          window.localStorage.removeItem(key)
        }
      }

      return null
    },
    setItem: (key, value) => {
      // Encrypt the store using our encryption token stored in cookies.
      const store = Crypto.AES.encrypt(value, encryptionToken).toString()

      // Save the encrypted store in local storage.
      return window.localStorage.setItem(key, store)
    },
    removeItem: (key) => window.localStorage.removeItem(key)
  }
})
const vuexSession = new VuexPersistence({
  modules: [
    'scd',
    'explorer',
    'graphql',
    'permission',
    'contracts',
    'apiEndpoints',
    'auth',
    'notifications'
  ],
  storage: window.sessionStorage
})
const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    scd,
    explorer,
    graphql,
    theme,
    user,
    auth,
    layout,
    event,
    permission,
    contracts,
    apiEndpoints,
    notifications
  },
  strict: debug,
  plugins: [vuexLocal.plugin, vuexSession.plugin, ...(debug ? [createLogger()] : [])]
})
