import meService from '@/services/me'
import get from 'lodash/get'

const initialState = () => ({
  accessToken: null,
  refreshToken: null,
  idToken: null,
  expiresAt: null,
  codeVerifier: null,
  refreshExpiresAt: 0,
  idExpiresAt: 0,
  acquiringToken: false
})

const state = initialState

const getters = {
  allTokens: (state) => {
    return {
      accessToken: state.accessToken,
      refreshToken: state.refreshToken,
      idToken: state.idToken,
      expiresAt: state.expiresAt,
      codeVerifier: state.codeVerifier,
      refreshExpiresAt: state.refreshExpiresAt,
      idExpiresAt: state.idExpiresAt
    }
  },
  accessToken: (state) => {
    return state.accessToken
  }
}

const actions = {
  async getMe({ commit }) {
    commit('user/SET', { path: 'me', key: 'loading', data: true }, { root: true })
    const res = await meService.getMe()
    commit(
      'user/SET',
      { path: 'me', key: 'data', data: res.success ? res.data : null },
      { root: true }
    )
    commit('user/SET', { path: 'me', key: 'loading', data: false }, { root: true })
  },
  setTokens({ commit }, payload) {
    Object.keys(payload).forEach((key) => {
      commit('SET', {
        path: null,
        key,
        data: payload[key]
      })
    })
  },
  setAcquiringToken({ commit }, payload) {
    commit('SET', {
      path: null,
      key: 'acquiringToken',
      data: payload.loading
    })
  },
  clearTokens({ commit }) {
    commit('RESET_ALL')
  }
}

const mutations = {
  SET(state, payload) {
    if (payload.path === null) {
      state[payload.key] = payload.data
    } else {
      const ref = get(state, payload.path)
      ref[payload.key] = payload.data
    }
  },
  RESET(state, payload) {
    const initial = initialState()
    const r = get(initial, payload.path)
    const ref = get(state, payload.path)
    Object.keys(ref).forEach((key) => {
      ref[key] = r[key]
    })
  },
  RESET_ALL(state) {
    const s = initialState()
    Object.keys(s).forEach((key) => {
      state[key] = s[key]
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
