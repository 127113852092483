<template>
  <v-app id="app">
    <NavBar />
    <v-main>
      <div class="d-flex flex-column full-height">
        <BannerContainer :config="configuration" />

        <div style="display: flex; position: relative">
          <div>
            <NavDrawer />
          </div>
          <div style="flex-grow: 1; width: 70%">
            <router-view />
          </div>
        </div>
      </div>
    </v-main>
    <!-- <BlocksLoader v-if="authLoading" /> -->
  </v-app>
</template>

<script>
/* eslint-disable no-undef */
import NavBar from '@/components/layout/main/navBar/NavBar'
import NavDrawer from '@/components/layout/main/navDrawer/NavDrawer'
import BannerContainer from '@/components/layout/shared/banner/container/BannerContainer'
// import BlocksLoader from '@/components/layout/shared/state/loading/BlocksLoader'

export default {
  name: 'App',
  components: {
    // BlocksLoader,
    NavDrawer,
    NavBar,
    BannerContainer
  },
  computed: {
    isDarkMode() {
      return this.$vuetify.theme.dark
    },
    authLoading() {
      return this.$store.state.user.me.loading
    },
    configuration() {
      return config
    }
  },
  watch: {
    isDarkMode() {
      this.$store.commit('theme/switchDarkMode', this.isDarkMode)
    }
  },
  created() {
    this.$vuetify.theme.dark = this.$store.state.theme.isDarkMode
  }
}
</script>

<style lang="scss">
@import '~@/styles';
</style>
