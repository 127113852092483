/* eslint-disable no-undef */
import { getInstance } from './authWrapper'
import { getOauthInstance } from './oauthWrapper'

const isOAuthDisabled = () => config.VUE_APP_AUTHZ_ENABLED !== 'TRUE'

export const authGuard = async (to, from, next) => {
  if (isOAuthDisabled()) {
    const auth = getInstance()
    auth.waitForLoaded((authed) => {
      if (authed) {
        next()
      } else {
        window.location.replace(auth.getLoginLink())
      }
    })
  } else {
    const auth = getOauthInstance()
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
    if (requiresAuth && !auth.isAuthenticated()) {
      auth.login()
    } else {
      await auth.refreshTokens()
      next()
    }
  }
}
