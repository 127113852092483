/* eslint-disable no-undef */
import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard, getOauthInstance } from '@/plugins/auth'

Vue.use(VueRouter)

const isOAuthDisabled = () => config.VUE_APP_AUTHZ_ENABLED !== 'TRUE'

const routes = [
  {
    path: '/',
    name: 'start',
    component: () => import('@/views/start/StartView'),
    meta: {
      requiresAuth: true,
      header: null,
      drawer: null,
      footer: null
    }
  },
  {
    path: '/404',
    name: '404.unscoped',
    component: () => import('@/views/404/UnscopedView'),
    meta: {
      requiresAuth: true,
      header: 'HomeNavBar',
      drawer: 'HomeNavDrawer',
      footer: null
    }
  },
  {
    path: '*',
    component: () => import('@/views/404/UnscopedView'),
    meta: {
      requiresAuth: true,
      header: 'HomeNavBar',
      drawer: 'HomeNavDrawer',
      footer: null
    }
  },
  {
    path: '/my/',
    component: () => import('@/views/home/HomeRootView'),
    children: [
      {
        path: 'organization',
        name: 'my.organization',
        component: () => import('@/views/home/children/OrganizationView'),
        meta: {
          requiresAuth: true,
          header: 'HomeNavBar',
          drawer: 'HomeNavDrawer',
          footer: null
        }
      },
      {
        path: 'invite',
        name: 'my.invite',
        component: () => import('@/views/home/children/InviteView'),
        meta: {
          requiresAuth: true,
          header: 'HomeNavBar',
          drawer: 'HomeNavDrawer',
          footer: null
        }
      },
      {
        path: 'secret',
        name: 'my.secret',
        component: () => import('@/views/home/children/SecretView'),
        meta: {
          requiresAuth: true,
          header: 'HomeNavBar',
          drawer: 'HomeNavDrawer',
          footer: null
        }
      },
      {
        path: 'profile',
        name: 'my.profile',
        component: () => import('@/views/home/children/ProfileView'),
        meta: {
          requiresAuth: true,
          header: 'HomeNavBar',
          drawer: 'HomeNavDrawer',
          footer: null
        }
      }
    ]
  },
  {
    path: '/admin/',
    component: () => import('@/views/admin/AdminRootMain'),
    children: [
      {
        path: '',
        name: 'admin.dashboard',
        component: () => import('@/views/admin/children/DashboardView'),
        meta: {
          requiresAuth: true,
          header: 'HomeNavBar',
          drawer: 'HomeNavDrawer',
          footer: null
        }
      }
    ]
  },
  {
    path: '/o/:organization/',
    component: () => import('@/views/organization/OrgRootView'),
    props: true,
    children: [
      {
        path: '404',
        name: '404.scoped',
        component: () => import('@/views/404/ScopedView'),
        meta: {
          requiresAuth: true,
          header: 'AppNavBar',
          drawer: 'OrgNavDrawer',
          footer: null,
          breadCrumbs: {
            component: 'AppBreadCrumbs',
            route: () => [
              {
                text: 'Dashboard',
                to: { name: 'dashboard' },
                replaceWith: 'organizationName',
                disabled: false
              },
              {
                text: '404',
                to: null,
                replaceWith: null,
                disabled: true
              }
            ]
          }
        }
      },
      {
        path: 'member',
        name: 'member',
        component: () => import('@/views/organization/management/MemberView'),
        meta: {
          requiresAuth: true,
          header: 'AppNavBar',
          drawer: 'OrgNavDrawer',
          footer: null,
          breadCrumbs: {
            component: 'AppBreadCrumbs',
            route: () => [
              {
                text: 'Dashboard',
                to: { name: 'dashboard' },
                replaceWith: 'organizationName',
                disabled: false
              },
              {
                text: 'Members',
                to: null,
                replaceWith: null,
                disabled: true
              }
            ]
          }
        }
      },
      {
        path: 'setting',
        name: 'setting',
        component: () => import('@/views/organization/management/SettingView'),
        beforeEnter: (to, from, next) => {
          const me = router.app.$store.state.user.me.data
          const currentOrganization = me.organisations?.find(
            (organisation) => organisation.name === to?.params?.organization
          )
          const userRoles = isOAuthDisabled()
            ? currentOrganization?.user_roles
            : currentOrganization?.roles?.map((role) => role.name)
          const ownerRoleName = isOAuthDisabled() ? 'Owner' : '_Owner'
          const adminRoleName = isOAuthDisabled() ? 'Admin' : '_Admin'
          if (userRoles?.includes(ownerRoleName) || userRoles?.includes(adminRoleName)) {
            next()
          } else {
            next(`/o/${to.params.organization}/404`)
          }
        },
        redirect: { name: 'setting.accounts' },
        children: [
          {
            path: 'accounts',
            name: 'setting.accounts',
            component: () => import('@/views/organization/management/settingView/AccountsView'),
            meta: {
              requiresAuth: true,
              header: 'AppNavBar',
              drawer: 'OrgNavDrawer',
              footer: null,
              breadCrumbs: {
                component: 'AppBreadCrumbs',
                route: () => [
                  {
                    text: 'Dashboard',
                    to: { name: 'dashboard' },
                    replaceWith: 'organizationName',
                    disabled: false
                  },
                  {
                    text: 'Settings',
                    to: null,
                    replaceWith: null,
                    disabled: true
                  }
                ]
              }
            }
          },
          {
            path: 'nodes',
            name: 'setting.nodes',
            component: () => import('@/views/organization/management/settingView/NodesView'),
            meta: {
              requiresAuth: true,
              header: 'AppNavBar',
              drawer: 'OrgNavDrawer',
              footer: null,
              breadCrumbs: {
                component: 'AppBreadCrumbs',
                route: () => [
                  {
                    text: 'Dashboard',
                    to: { name: 'dashboard' },
                    replaceWith: 'organizationName',
                    disabled: false
                  },
                  {
                    text: 'Settings',
                    to: null,
                    replaceWith: null,
                    disabled: true
                  }
                ]
              }
            }
          },
          {
            path: 'storage',
            name: 'setting.storage',
            component: () => import('@/views/organization/management/settingView/StorageView'),
            meta: {
              requiresAuth: true,
              header: 'AppNavBar',
              drawer: 'OrgNavDrawer',
              footer: null,
              breadCrumbs: {
                component: 'AppBreadCrumbs',
                route: () => [
                  {
                    text: 'Dashboard',
                    to: { name: 'dashboard' },
                    replaceWith: 'organizationName',
                    disabled: false
                  },
                  {
                    text: 'Settings',
                    to: null,
                    replaceWith: null,
                    disabled: true
                  }
                ]
              }
            }
          }
        ]
      },
      {
        path: 'subscription',
        name: 'subscription',
        component: () => import('@/views/organization/subscription/SubscriptionView'),
        redirect: { name: 'subscription.applications' },
        children: [
          {
            path: 'wallets',
            name: 'subscription.wallets',
            component: () =>
              import('@/views/organization/subscription/tableView/WalletSubscriptionView'),
            meta: {
              requiresAuth: true,
              header: 'AppNavBar',
              drawer: 'OrgNavDrawer',
              footer: null,
              breadCrumbs: {
                component: 'AppBreadCrumbs',
                route: () => [
                  {
                    text: 'Dashboard',
                    to: { name: 'dashboard' },
                    replaceWith: 'organizationName',
                    disabled: false
                  },
                  {
                    text: 'Subscriptions',
                    to: null,
                    replaceWith: null,
                    disabled: true
                  }
                ]
              }
            }
          },
          {
            path: 'applications',
            name: 'subscription.applications',
            component: () =>
              import('@/views/organization/subscription/tableView/ApplicationSubscriptionView'),
            meta: {
              requiresAuth: true,
              header: 'AppNavBar',
              drawer: 'OrgNavDrawer',
              footer: null,
              breadCrumbs: {
                component: 'AppBreadCrumbs',
                route: () => [
                  {
                    text: 'Dashboard',
                    to: { name: 'dashboard' },
                    replaceWith: 'organizationName',
                    disabled: false
                  },
                  {
                    text: 'Subscriptions',
                    to: null,
                    replaceWith: null,
                    disabled: true
                  }
                ]
              }
            }
          }
        ]
      },
      {
        path: '',
        name: 'dashboard',
        component: () => import('@/views/organization/dashboard/DashboardView'),
        meta: {
          requiresAuth: true,
          header: 'AppNavBar',
          drawer: 'OrgNavDrawer',
          footer: null,
          breadCrumbs: {
            component: 'AppBreadCrumbs',
            route: () => [
              {
                text: 'Dashboard',
                to: null,
                replaceWith: 'organizationName',
                disabled: true
              }
            ]
          }
        }
      },
      {
        path: 'app',
        component: () => import('@/views/organization/application/AppRootView'),
        props: true,
        children: [
          {
            path: '',
            name: 'app.list',
            component: () => import('@/views/organization/application/children/ListView'),
            meta: {
              requiresAuth: true,
              header: 'AppNavBar',
              drawer: 'OrgNavDrawer',
              footer: null,
              breadCrumbs: {
                component: 'AppBreadCrumbs',
                route: () => [
                  {
                    text: 'Dashboard',
                    to: { name: 'dashboard' },
                    replaceWith: 'organizationName',
                    disabled: false
                  },
                  {
                    text: 'Applications',
                    to: null,
                    replaceWith: null,
                    disabled: true
                  }
                ]
              }
            }
          },
          {
            path: ':application/explorer/:api',
            name: 'app.explorer',
            component: () => import('@/views/organization/application/children/query/ExplorerView'),
            meta: {
              requiresAuth: true,
              header: null,
              drawer: null,
              footer: null,
              breadCrumbs: null
            }
          },
          {
            path: ':application/gql/:api',
            name: 'app.gql',
            component: () => import('@/views/organization/application/children/query/GqlView'),
            meta: {
              requiresAuth: true,
              header: null,
              drawer: null,
              footer: null,
              breadCrumbs: null
            }
          },
          {
            path: ':application',
            props: true,
            component: () => import('@/views/organization/application/children/DetailView'),
            children: [
              {
                path: '',
                name: 'app.view',
                component: () =>
                  import('@/views/organization/application/children/detailView/OverviewView'),
                meta: {
                  requiresAuth: true,
                  header: 'AppNavBar',
                  drawer: 'AppNavDrawer',
                  footer: null,
                  breadCrumbs: {
                    component: 'AppBreadCrumbs',
                    route: () => [
                      {
                        text: 'Dashboard',
                        to: { name: 'dashboard' },
                        replaceWith: 'organizationName',
                        disabled: false
                      },
                      {
                        text: 'Applications',
                        to: { name: 'app.list' },
                        replaceWith: null,
                        disabled: false
                      },
                      {
                        text: 'Application',
                        to: null,
                        replaceWith: 'applicationName',
                        disabled: true
                      }
                    ]
                  }
                }
              },
              {
                path: 'contract',
                name: 'app.view.contract',
                component: () =>
                  import('@/views/organization/application/children/detailView/ContractView'),
                meta: {
                  requiresAuth: true,
                  header: 'AppNavBar',
                  drawer: 'AppNavDrawer',
                  footer: null,
                  breadCrumbs: {
                    component: 'AppBreadCrumbs',
                    route: () => [
                      {
                        text: 'Dashboard',
                        to: { name: 'dashboard' },
                        replaceWith: 'organizationName',
                        disabled: false
                      },
                      {
                        text: 'Applications',
                        to: { name: 'app.list' },
                        replaceWith: null,
                        disabled: false
                      },
                      {
                        text: 'Application',
                        to: { name: 'app.view' },
                        replaceWith: 'applicationName',
                        disabled: false
                      },
                      {
                        text: 'Contracts',
                        to: null,
                        replaceWith: null,
                        disabled: true
                      }
                    ]
                  }
                }
              },
              {
                path: 'transaction',
                name: 'app.view.transaction',
                component: () =>
                  import('@/views/organization/application/children/detailView/TransactionView'),
                meta: {
                  requiresAuth: true,
                  header: 'AppNavBar',
                  drawer: 'AppNavDrawer',
                  footer: null,
                  breadCrumbs: {
                    component: 'AppBreadCrumbs',
                    route: () => [
                      {
                        text: 'Dashboard',
                        to: { name: 'dashboard' },
                        replaceWith: 'organizationName',
                        disabled: false
                      },
                      {
                        text: 'Applications',
                        to: { name: 'app.list' },
                        replaceWith: null,
                        disabled: false
                      },
                      {
                        text: 'Application',
                        to: { name: 'app.view' },
                        replaceWith: 'applicationName',
                        disabled: false
                      },
                      {
                        text: 'Transactions',
                        to: null,
                        replaceWith: null,
                        disabled: true
                      }
                    ]
                  }
                }
              },
              {
                path: 'subscription',
                name: 'app.view.subscription',
                component: () =>
                  import('@/views/organization/application/children/detailView/SubscriptionView'),
                meta: {
                  requiresAuth: true,
                  header: 'AppNavBar',
                  drawer: 'AppNavDrawer',
                  footer: null,
                  breadCrumbs: {
                    component: 'AppBreadCrumbs',
                    route: () => [
                      {
                        text: 'Dashboard',
                        to: { name: 'dashboard' },
                        replaceWith: 'organizationName',
                        disabled: false
                      },
                      {
                        text: 'Applications',
                        to: { name: 'app.list' },
                        replaceWith: null,
                        disabled: false
                      },
                      {
                        text: 'Application',
                        to: { name: 'app.view' },
                        replaceWith: 'applicationName',
                        disabled: false
                      },
                      {
                        text: 'Subscriptions',
                        to: null,
                        replaceWith: null,
                        disabled: true
                      }
                    ]
                  }
                }
              },
              {
                path: 'setting',
                name: 'app.view.setting',
                component: () =>
                  import('@/views/organization/application/children/detailView/SettingView'),
                meta: {
                  requiresAuth: true,
                  header: 'AppNavBar',
                  drawer: 'AppNavDrawer',
                  footer: null,
                  breadCrumbs: {
                    component: 'AppBreadCrumbs',
                    route: () => [
                      {
                        text: 'Dashboard',
                        to: { name: 'dashboard' },
                        replaceWith: 'organizationName',
                        disabled: false
                      },
                      {
                        text: 'Applications',
                        to: { name: 'app.list' },
                        replaceWith: null,
                        disabled: false
                      },
                      {
                        text: 'Application',
                        to: { name: 'app.view' },
                        replaceWith: 'applicationName',
                        disabled: false
                      },
                      {
                        text: 'Settings',
                        to: null,
                        replaceWith: null,
                        disabled: true
                      }
                    ]
                  }
                }
              }
            ]
          }
        ]
      },
      {
        path: 'contract',
        component: () => import('@/views/organization/contract/ContractRootView'),
        props: true,
        children: [
          {
            path: '',
            name: 'contract.list',
            component: () => import('@/views/organization/contract/children/ListView'),
            meta: {
              requiresAuth: true,
              header: 'AppNavBar',
              drawer: 'OrgNavDrawer',
              footer: null,
              breadCrumbs: {
                component: 'AppBreadCrumbs',
                route: () => [
                  {
                    text: 'Dashboard',
                    to: { name: 'dashboard' },
                    replaceWith: 'organizationName',
                    disabled: false
                  },
                  {
                    text: 'Contracts',
                    to: null,
                    replaceWith: null,
                    disabled: true
                  }
                ]
              }
            }
          },
          {
            path: 'scd',
            name: 'contract.view',
            component: () => import('@/views/organization/contract/children/DetailView'),
            meta: {
              requiresAuth: true,
              header: null,
              drawer: null,
              footer: null,
              breadCrumbs: null
            }
          }
        ]
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

// Handle the redirect callback on app initialization
router.beforeEach(async (to, from, next) => {
  if (window.location.search.includes('code=')) {
    try {
      const auth = getOauthInstance()
      await auth.handleRedirectCallback()
      next(to.path) // Continue to the intended route
    } catch (error) {
      console.error('Authentication failed:', error)
      next('/')
    }
  } else {
    next()
  }
})

router.beforeEach(authGuard)

export default router
